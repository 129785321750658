import React, { Component } from 'react'
import * as Roles from '../constants/roles'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'     //Display its children in a column
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'   //Display its children in a row
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import ErrorDialog from '../containers/ErrorDialog'

const AppName = 'DANGEROUS GOODS'

const styles = (theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  title: {
    cursor: 'pointer',
    color:'white' ,
    font:'sans-serif'
  },
  titleWrapper: {
    display: 'flex',
    flex: 1
  },
  toolbar: theme.mixins.toolbar
})

class App extends Component {

 handleLogOut = () => {
    // console.log('[components.App] handleLogOut');
    const { logOut } = this.props             //Gets logOut() function passed from containers.App.mapDispatchToProps
    logOut()
  }

  render () {
    const { classes, isLoggedIn, onTitleClick, onAdminClick, role } = this.props     //Gets isLoggedIn prop, onTitleClick() function passed from containers.App.mapDispatchToProps
    // console.log('this.props', this.props);
    if (isLoggedIn) {
      return (
        <div>
          <AppBar className={classes.appBar} position='fixed'>
            <Toolbar>
              <div className={classes.titleWrapper} >
                <Typography color='inherit' variant='h6'>
                  <span className={classes.title} onClick={onTitleClick}>{AppName}</span>
                </Typography>
              </div>
              <div>
                <Button color="inherit" onClick={onTitleClick}>Home</Button>
              </div>
              {role === Roles.DANGEROUS_GOODS_ADMIN  ? 
              <div>
                <Button color="inherit" onClick={onAdminClick}>Admin</Button> 
              </div>
              :
              ''}
              {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY  ? 
                <div >
                  <Link to='/DangerousGoodsUserGuideInternal.docx' target="_blank" download>
                      <Button>
                        <Typography color='white' font='sans-serif' variant='subtitle 1'>
                            <span className={classes.title} >USER GUIDE</span>
                        </Typography>
                    </Button>
                </Link>
              </div>
              :
              ''}
              {role === Roles.CARRIER || role === Roles.AGENT ? 
                <div >
                <Link to='/DangerousGoodsUserGuideExternal.docx' target="_blank" download>
                    <Button >
                        <Typography color='white' font='sans-serif' variant='subtitle 1'>
                            <span className={classes.title} >USER GUIDE</span>
                        </Typography>
                    </Button>
                </Link>
              </div>
              :
              ''}
              {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY || role === Roles.INSPECTION_SERVICE || role === Roles.CUSTOMER_SERVICE_AGENT ? 
                <div >
                    <Link to='/DangerousGoodsGeneralGuidelines.docx' target="_blank" download>
                        <Button>
                          <Typography color='white' font='sans-serif' variant='subtitle 1'>
                              <span className={classes.title} >GENERAL GUIDELINES</span>
                          </Typography>
                      </Button>
                  </Link>
                </div>
              :
              ''}
              {/* <Button color="inherit">PlaceHolder</Button> */}
              <Button color="inherit" onClick={this.handleLogOut}>Log out</Button>       
            </Toolbar>
          </AppBar>
          <div className={classes.toolbar} />
          <ErrorDialog />       
        </div>
      )
    } else {
      return (
        <AppBar position='static'>
          <Toolbar>
            <Typography color='inherit' variant='h6'>{AppName}</Typography>
          </Toolbar>
        </AppBar>
      )
    }
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  logOut: PropTypes.func.isRequired,          //Declares and requires logOut() function passed from containers.App.mapDispatchToProps
  isLoggedIn: PropTypes.bool.isRequired,      //Declares and requires isLoggedIn prop passed from containers.App.mapDispatchToProps
  onTitleClick: PropTypes.func.isRequired     //Declares and requires onTitleClick() function passed from containers.App.mapDispatchToProps
}

export default withStyles(styles)(App)
