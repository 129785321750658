import React from 'react'
import { Link } from 'react-router-dom'
import { indigo } from '@material-ui/core/colors'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { withStyles } from '@material-ui/core/styles'
import StatusTooltip from '../../tooltips/StatusTooltip'
import * as DateUtils from '../../../utilities/date-utils'
import styles from './styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import * as Incidents from '../../../constants/incidents'
import * as Roles from '../../../constants/roles'

const getUpdated = (hmLog) => {
  hmLog.sort((a, b) => (a.actionDate < b.actionDate) ? 1 : -1)
  return hmLog
}

const IncidentTableRow = ({ classes, incidentApprovals, onApprovalChange, selectedTab, onSerialNumberClick, row, role }) => (
  <TableRow>
    {(role===Roles.DANGEROUS_GOODS_ADMIN_READONLY || role===Roles.CUSTOMER_SERVICE_AGENT || role===Roles.INSPECTION_SERVICE) && 
    <TableCell className={classes.tableCell}><Link
        onClick={() => onSerialNumberClick(row)}
        style={{ color: indigo[300] }}
        to={'/view'}
      >
        {/* TODONE:  Display abbrev of agency Reporting Incident, if incident was reported by an agency, else display carrier */}
        { 'AGENT'===row.entityReportingIncident && row.agencyReportingIncident }
        { 'AGENT'!==row.entityReportingIncident && row.airlineReportingIncident }        
        -{DateUtils.formatIdDate(row.dateReported)}-{row.hmKey}
      </Link>
    </TableCell>
    }{((role===Roles.DANGEROUS_GOODS_ADMIN || role===Roles.CARRIER || role===Roles.AGENT) && row.status === Incidents.CLOSED) &&
    <TableCell className={classes.tableCell}>
      <Link
        onClick={() => onSerialNumberClick(row)}
        style={{ color: indigo[500] }}
        to={'/view'}
      >
        {/* TODONE:  Display agency Reporting Incident abbrev, if incident was reported by an agency, else display carrier */}
        { 'AGENT'===row.entityReportingIncident && row.agencyReportingIncident }
        { 'AGENT'!==row.entityReportingIncident && row.airlineReportingIncident }           
        -{DateUtils.formatIdDate(row.dateReported)}-{row.hmKey}
      </Link>
    </TableCell>   
    }{((role===Roles.DANGEROUS_GOODS_ADMIN || role===Roles.CARRIER || role===Roles.AGENT) && row.status !== Incidents.CLOSED) &&
    <TableCell className={classes.tableCell}>
      <Link
        onClick={() => onSerialNumberClick(row)}
        style={{ color: indigo[500] }}
        to={'/edit'}
      >
        {/* TODONE:  Display agency Reporting Incident, if incident was reported by an agency, else display carrier abbrev */}
        { 'AGENT'===row.entityReportingIncident && row.agencyReportingIncident }
        { 'AGENT'!==row.entityReportingIncident && row.airlineReportingIncident }           
        -{DateUtils.formatIdDate(row.dateReported)}-{row.hmKey}
      </Link>
    </TableCell>
    }
    <TableCell className={classes.tableCell}>{row.barcodeCustoms}</TableCell>
    <TableCell className={classes.tableCell}>{row.reportingContactName}</TableCell>
    <TableCell className={classes.tableCell}>{row.reportingAirportHub}</TableCell>
    {selectedTab === 3 ?
      <TableCell className={classes.tableCell}><StatusTooltip status={row.status}><span>{row.status}</span></StatusTooltip></TableCell>
      : ''}
    <TableCell className={classes.tableCell}>{row.initialFinding}</TableCell>
    <TableCell className={classes.tableCell}>{row.airlineReportingIncident}</TableCell>    
    <TableCell className={classes.tableCell}>{row.agencyReportingIncident}</TableCell>  
    <TableCell className={classes.tableCell}>{row.entityReportingIncident}</TableCell>
    <TableCell className={classes.tableCell}>{DateUtils.formatDate((row.dateReported))}</TableCell>
    <TableCell className={classes.tableCell}>{DateUtils.formatDate(row.actionDate)}</TableCell>
    <TableCell className={classes.tableCell}>{row.updtUser}</TableCell>
 </TableRow>
)

const mapStateToProps = (state) => {
  const selectedTab = state.tabs.incidents
    
  return {
    selectedTab
  }
}
   
export default compose(withStyles(styles), connect(mapStateToProps))(IncidentTableRow)