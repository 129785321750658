import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { indigo } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'


import PageContainer from './PageContainer'

const styles = {
  // message: {
  //   left: 0,
  //   position: 'absolute',
  //   textAlign: 'center',
  //   top: '70px',
  //   width: '100%'
  // },
  // messageFilter: {
  //   top: '150px'
  // }
}

class Maintenance extends Component {
 
  // componentDidMount () {
  //   console.log('[components.Welcome] componentDidMount ')
  //   this.clickLink(null);
  // }

  // clickLink = (event) => {
  //   const { onTabChange } = this.props
  //   console.log('[components.Welcome] clickLink')
  //   document.getElementById('linkid').click();
  // }

  render () {
    // console.log('[components.Welcome] render ')
    const { role} = this.props
    return (
      <PageContainer>

                <Typography variant="h5">
                    The Dangerous Goods application is currently under maintenance...
                </Typography>

      </PageContainer>
    )
  }
}

export default withStyles(styles)(Maintenance)
