import { connect } from 'react-redux'
import Home from '../components/Home'
import { getIncidentCriteria } from '../selectors/incident'
import { getIncidentsPage } from '../services/incident'
import * as TabActions from '../actions/tab'

const mapStateToProps = (state, ownProps) => {
  // console.log("[containers/HomePage] mapStateToProps")
  const role = state.user.role
  const selectedTab = state.tabs.incidents
  const page = state.table.incidentsTable.page
    // console.log("[containers/HomePage] selectedTab=",selectedTab)  
  const onLoadHazmatData = (filter, start, count) => {
    // console.log("[containers/HomePage] onLoadHazmatData - calling getIncidentCriteria" )  
    const criteria = getIncidentCriteria(state, filter)
    return getIncidentsPage(criteria, start, count)
  }
  return {
    onLoadHazmatData,
    role,
    page,
    selectedTab
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    const onTabChange = (value) => {
      // console.log("[containers/HomePage.mapDispatchToProps] onTabChange value=",value)  
        dispatch(TabActions.change('incidents', value))
      }
  return {
    onTabChange
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
