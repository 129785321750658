import { fetchWordFile } from '../utilities/service-utils'
import * as Roles from '../constants/roles'
import * as IncidentServices from '../services/incident'
import * as Incidents from '../constants/incidents'

import * as ErrorActions from './error'
import * as SnackbarActions from './snackbar'
import config from '../config'

export const SELECT_INCIDENT = 'SELECT_INCIDENT'
export const SAVE_INCIDENT_SUCCESS = 'SAVE_INCIDENT_SUCCESS'
export const SELECT_INCIDENT_IMAGES = 'SELECT_INCIDENT_IMAGES'
export const SELECT_INCIDENT_WORKNOTES = 'SELECT_INCIDENT_WORKNOTES'
export const ADD_STAGE_IMAGE = 'ADD_STAGE_IMAGE'
export const DELETE_STAGED_IMAGES = 'DELETE_STAGED_IMAGES'
export const UPDATE_WORK_NOTE = 'UPDATE_WORK_NOTE'
export const FIELD_CHANGE = 'FIELD_CHANGE'
export const DELETE_CURRENT_NOTE = 'DELETE_CURRENT_NOTE'
export const MARK_COMPLETE = 'MARK_COMPLETE'
export const CLEAR_SELECTED_IMAGES = 'CLEAR_SELECTED_IMAGES'

/**
 * 
 * @param {*} name 
 * @param {*} value 
 * @returns 
 */
export const handleFieldChange = (name, value) => {
  return {
    type: FIELD_CHANGE,
    name,
    value
  }
}

/**
 * 
 * @param {*} incident 
 * @returns 
 */
export const selectIncident = (incident) => {
  // console.log('[actions/incident] selectIncident')
  return {
    type: SELECT_INCIDENT,
    incident
  }
}

/**
 * 
 * @param {*} hmkey 
 * @returns 
 */
export const retrieveItemImages = (hmkey) => (dispatch) => {
  return IncidentServices.fetchIncidentImages(hmkey)
    .then(res => {
      dispatch({
        type: SELECT_INCIDENT_IMAGES,
        selImages: res
      })
    })
}

/**
 * 
 * @param {*} hmkey 
 * @returns 
 */
export const retrieveWorkNotes = (hmkey) => (dispatch) => {
  return IncidentServices.fetchWorkNotes(hmkey)
    .then(res => {
      dispatch({
        type: SELECT_INCIDENT_WORKNOTES,
        selWorkNotes: res
      })
    })
}

/**
 * 
 * @param {*} hmkey 
 * @param {*} file 
 * @returns 
 */
export const uploadImage = (hmkey, file) => async (dispatch) =>{
  return IncidentServices.insertIncidentImage(hmkey, file)
    .then((res =>{
      IncidentServices.fetchIncidentImages(hmkey)
        .then((res =>{
          dispatch({
            type: SELECT_INCIDENT_IMAGES,
            selImages: res
          })
        }))
    }))
  
}

/**
 * 
 * @param {*} file 
 * @returns 
 */
export const stageImage = (file) => async (dispatch) => {
  dispatch({
    type: ADD_STAGE_IMAGE,
    stageFile: file
  })
}

/**
 * 
 * @returns 
 */
export const deleteStagedImages = () => {
  return {
    type: DELETE_STAGED_IMAGES
  }
}

/**
 * 
 * @returns 
 */
export const clearSelectedImages = () => {
  return {
    type: CLEAR_SELECTED_IMAGES
  }
}


/**
 * Action bound to redux form TrackerForm
 * @param {*} values 
 * @returns 
 */
export const trackerFormSubmit = (values) => (dispatch, getState) => {
  // console.log('[actions/incident] trackerFormSubmit')
  const state = getState()
  // console.log('[actions/incident] trackerFormSubmit', values)
  return IncidentServices.createIncident(values, state.user.userId)
    .then((res) => {
      let wn = {'hmKey': res.hmKey,
        'updtUserId': state.user.userId,
        'updtDate': new Date(),
        'workNote': state.incident.current_note}
      IncidentServices.insertWorkNote(res.hmKey, wn, state.user.userId)
      // console.log('RES   ' + res.hmKey)
      return res
    })
    .then((res) => {
      let imgs = state.incident.staged_images
      imgs.forEach((img)=>{
        return fetch(`${config.ENDPOINT}/hazmat/uploadImageBase64/${res.hmKey}`, {
          headers: {
            Authorization: state.user.authToken,
            'cache-control': 'no-cache',
            pragma: 'no-cache',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            image: img.imageFile.dataUrl
          })
        })
        
      })     
    })
    .then(() => {
      return {
        type: DELETE_STAGED_IMAGES
      }
    })
    .then(() => {
      dispatch(SnackbarActions.open('trackerForm'))
    })
    .catch((e) => {
      const errObj=JSON.parse(e);
      // console.log('received error message:', errObj);
      dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create incident:tfs:'+e.message }))
    })
}

/**
 * 
 * @returns 
 */
export const submitDraft = () => (dispatch, getState) => {
  // console.log('[actions/incident]  submitDraft  ')
  const state = getState()
  // console.log('[actions/incident] submitDraft', state.form.TrackerForm.values)
   
 return IncidentServices.saveDraft(state.form.TrackerForm.values, state.user.userId)
     .then((res) => {
      if(state.incident.current_note != null && state.incident.current_note !== ''){
        let wn = {'hmKey': res.hmKey,
          'updtUserId': state.user.userId,
          'updtDate': new Date(),
          'workNote': state.incident.current_note}
        IncidentServices.insertWorkNote(res.hmKey, wn, state.user.userId)
        // console.log('RES   ' + res.hmKey)
      }
      return res
    }) 
    .then((res) => {
      let imgs = state.incident.staged_images
      imgs.forEach((img)=>{
      
        return fetch(`${config.ENDPOINT}/hazmat/uploadImageBase64/${res.hmKey}`, {
          headers: {
            Authorization: state.user.authToken,
            'cache-control': 'no-cache',
            pragma: 'no-cache',
            'Content-Type': 'application/json'
          },
          method: 'POST',
          body: JSON.stringify({
            image: img.imageFile.dataUrl
          })
        })
      })    
    })
    .then(() =>{
      return {
        type: DELETE_STAGED_IMAGES
      }
    })
    .then(() => {
      dispatch(SnackbarActions.open('trackerForm'))
    })    
    .catch((e) => {
      const errObj=JSON.parse(e);
      // console.log('received error message:', errObj);
      dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create incident: ' }))
    })
}

/**
 * 
 * @returns 
 */
 export const submitEditDraft = () => (dispatch, getState) => {
  // console.log('[actions/incident]  submitEditDraft ')  
  const state = getState()

  return IncidentServices.updateIncident(state.incident.selected_incident, state.user.userId)
     .then((res) => {
      if(state.incident.current_note){
        let wn = {'hmKey': res.hmKey,
          'updtUserId': state.user.userId,
          'updtDate': new Date(),
          'workNote': state.incident.current_note}   
            IncidentServices.insertWorkNote(res.hmKey, wn, state.user.userId)
      }
      return res
    })
    .then(()=>{
      dispatch({
        type: DELETE_CURRENT_NOTE  
      })
    }) 
    .then(() => {
      return 'success'
    })    
    .catch((e) => {
      const errObj=JSON.parse(e);
      // console.log('received error message:', errObj);
      dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create incident:' }))
    })
}

  export const submitEditComplete = () => (dispatch, getState) => {
    // console.log('[actions/incident]  submitEditComplete ')
    const state = getState()  
   return IncidentServices.updateIncident(state.incident.selected_incident, state.user.userId)
       .then((res) => {
        if(state.incident.current_note){
          let wn = {'hmKey': res.hmKey,
            'updtUserId': state.user.userId,
            'updtDate': new Date(),
            'workNote': state.incident.current_note}   
              IncidentServices.insertWorkNote(res.hmKey, wn, state.user.userId)
        }
        return res
      })
      .then(()=>{
        dispatch({
          type: DELETE_CURRENT_NOTE    
        })
      }) 
      .then(() => {
        return {
          type: MARK_COMPLETE
        }
      })    
      .catch((e) => {
        const errObj=JSON.stringify(e);
        // console.log('received error message:', errObj);
        dispatch(ErrorActions.openErrorDialog({ errorText: errObj.message, title: 'Could not create incident:' }))
      })
  }  

  /**
   * 
   * @returns 
   */
export const deleteIncident = () => ( dispatch, getState)=>{
  // console.log('[actions/incident]  deleteIncident  ')
  const state = getState()
  if (state.user.role === Roles.DANGEROUS_GOODS_ADMIN || state.user.role === Roles.CARRIER  || state.user.role === Roles.AGENT) {
        return IncidentServices.deleteIncident(state.incident.selected_incident, state.user.userId)
  }
  else{
    return 'unauthorized'
  }
}
 
/**
 * 
 * @returns 
 */
export const closeIncident = () => (dispatch, getState)=> {
  // console.log('[actions/incident]  closeIncident  ')
  const state = getState()
  state.incident.selected_incident.status = Incidents.CLOSED
  if(state.user.role === Roles.DANGEROUS_GOODS_ADMIN || state.user.role === Roles.INSPECTION_SERVICE){
        return IncidentServices.updateIncident(state.incident.selected_incident, state.user.userId)   
  }
  else{
    //Carrier should not be able to Close Incident.  Raise an exception if they try.
    return 'unauthorized'
  }
}

/**
 * 
 * @param {*} worknote 
 * @returns 
 */
export const updateWorkNote = (worknote) => (dispatch) => {
  dispatch({
    type: UPDATE_WORK_NOTE,
    note: worknote
  })
}

//TODO: remove me from the Action
export const generateWord = (hmKey) => (dispatch) => {
  // console.log('[actions/incident]  generateWord  hmKey=',hmKey)
  const wordFile = fetchWordFile('hazmat/generateLetter',hmKey)
  // console.log('[actions/incident] generateWord -- got: ', wordFile)
  return wordFile
}
