import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as ErrorActions from '../../actions/error'
import * as IncidentActions from '../../actions/incident'
import * as SnackbarActions from '../../actions/snackbar'
import * as Roles from '../../constants/roles'
import * as HmLogAction from '../../actions/hmlog'
import ViewForm from '../../components/ViewForm'

const getInitialValues = (incident, role) => {
  // console.log('[containers/ViewForm] getInitialValues')
  if (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.DANGEROUS_GOODS_ADMIN_READONLY ||  role === Roles.CARRIER || role === Roles.AGENT) {
    return {
      hmkey: incident.hmKey
    }
  }
  return {}
}

const mapStateToProps = (state, ownProps) => {
  // console.log('[containers/ViewForm] mapStateToProps')
  const incident = state.incident.selected_incident
  const hm_logs = state.hmlog.selected_logs
  const role = state.user.role
  const snackbarOpen = state.snackbars.reportDetail
  const selimages = state.incident.selected_incident_images || {}
  return {
    incident,
    initialValues: getInitialValues(incident, role),
    role,
    snackbarOpen,
    selimages,
    hm_logs
  }
}

//I'm not sure when the last time is that we exercised the READ_ONLY functionality.  How would I run this in DEV?
//I don't think deleteIncident, closeIncident, uploadImage, onSubmit, saveDraft, deleteStagedImages, handleFieldChange ever occur to this read-only incident.
//Some of the other actions may be possible but I'm not sure.
const mapDispatchToProps = (dispatch, ownProps) => {
  // console.log('[containers/ViewForm] mapDispatchToProps')
  const onError = (errorText, title) => {
    // console.log('[containers/ViewForm] mapDispatchToProps -- onError')
    dispatch(ErrorActions.openErrorDialog({ errorText, title }))
  }

  const onSnackbarClose = () => {
    // console.log('[containers/ViewForm] mapDispatchToProps -- onSnackbarClose')
    dispatch(SnackbarActions.close('reportDetail'))
  }

  const onSnackbarOpen = () => {
    // console.log('[containers/ViewForm] mapDispatchToProps -- onSnackbarOpen')    
    dispatch(SnackbarActions.open('reportDetail'))
  }

  const deleteIncident = () => {
    dispatch(IncidentActions.deleteIncident())
    .then(() => {
        ownProps.history.push('/home')
    })
  }

  const closeIncident = () => {
    dispatch(IncidentActions.closeIncident())
    .then(() => {
        ownProps.history.push('/home')
    })
  }
  const fetchLogs = (hmkey) =>{
    // console.log('[containers/ViewForm] mapDispatchToProps -- fetchLogs')       
    dispatch(HmLogAction.fetchHmLogs(hmkey))
  }

  const uploadImage = (file, hmkey) => {
    // console.log('FILE IS ')
    // console.log(file)
      dispatch(IncidentActions.uploadImage(hmkey,file))
      dispatch(IncidentActions.retrieveItemImages(hmkey))
  }

  const fetchImages = (hmkey) => {
    // console.log('[containers/ViewForm] mapDispatchToProps -- fetchImages') 
      dispatch(IncidentActions.retrieveItemImages(hmkey))
  }

    //I don't think the ViewForm can ever SUBMIT
  const onSubmit = () => {
    // console.log('onSubmit...')
    return new Promise((resolve) => {
      // console.log('...resolve complete')      
      resolve('complete')
   })
    .then(() => {
      // console.log('...Calling dispatch IncidentActions.submitEditComplete')
      return dispatch(IncidentActions.submitEditComplete())
    })     
    .then(() => {
      console.log('...Calling saveDraft')
      return saveDraft()
    })
  }

  //I don't think the ViewForm can ever SAVE
  const saveDraft = () =>{
    dispatch(IncidentActions.submitEditDraft())
    .then((success) => {
      // console.log('[ViewForm] mapDispatchToProps - dispatch IncidentActions.submitEditDraft - then - success =', success)
      if (success) {
        // ownProps.history.push('/home')
        window.location.reload()
      }
    })
  }

  const deleteStagedImages = () => {
    dispatch(IncidentActions.deleteStagedImages())
  }

  const handleFieldChange = (name, value) =>{      
    dispatch(IncidentActions.handleFieldChange(name, value))
  }

  const generateWord = (hmKey) => {
    // console.log('[containers/EditForm] mapDispatchToProps - generateWord called  hmKey=',hmKey);
    const rval = dispatch(IncidentActions.generateWord(hmKey));
    // console.log('[containers/EditForm] mapDispatchToProps - generateWord returned',rval);
    return rval;
  }

  return {
    onError,
    onSnackbarClose,
    onSnackbarOpen,
    onSubmit,
    uploadImage,
    fetchImages,
    saveDraft,
    fetchLogs,
    closeIncident,
    deleteIncident,
    deleteStagedImages,
    generateWord,
    handleFieldChange
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ViewForm))
