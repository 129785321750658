import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

const IncidentTableSearch = ({ classes, filter, onFilterChange, selectedTab }) => (

  <TableRow>
      <TableCell className={classes.tableCell}  style={{verticalAlign: 'bottom'}}>
      <TextField
        label="Search Incidents" 
        variant='outlined'
        onChange={onFilterChange('SearchLike')}
        value={filter.SearchLike || ''}
        InputProps={{
          inputProps: {className: classes.input},
          startAdornment: (
            <InputAdornment position="start"> 
              <IconButton aria-label="search" disabled> 
                <SearchIcon /> 
              </IconButton> 
            </InputAdornment> 
          )
          }} 
      />
    </TableCell> 
  </TableRow>
)

const mapStateToProps = (state) => {
  const selectedTab = state.tabs.incidents
  return {
    selectedTab
  }
}

export default compose(withStyles(styles), connect(mapStateToProps))(IncidentTableSearch)
