import React from 'react'
// import { Packer} from 'docx'  // May wish to use in future to edit generated Word documents online within a React app window
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dropzone from 'react-dropzone'
import * as DateUtils from '../utilities/date-utils'
import PageContainer from './PageContainer'
import TextField from '@material-ui/core/TextField'
import * as Roles from '../constants/roles'
import * as Incidents from '../constants/incidents'
import AirportDestinationSelect from '../containers/inputs/AirportDestinationSelect'
import AirportOriginSelect from '../containers/inputs/AirportOriginSelect'
import InitialFindingSelect from '../containers/inputs/InitialFindingSelect'
import IcaoHazardClassSelect from '../containers/inputs/IcaoHazardClassSelect'
import IssueIdentifierSelect from '../containers/inputs/IssueIdentifierSelect'
import IssueTypeSelect from '../containers/inputs/IssueTypeSelect'
import DangerousItemSelect from '../containers/inputs/DangerousItemSelect'
import RestrictedItemSelect from '../containers/inputs/RestrictedItemSelect'
import UsStateSelect from '../containers/inputs/UsStateSelect'
import LetterMailedSelect from '../containers/inputs/LetterMailedSelect'
//--Received has been intentionally misspelled as receivd because 'CUSTOMER_LETTER_RECEIVD_STATUS' is 30 characters long -- this is the maximum name length allowed by our Oracle database.
import LetterReceivdSelect from '../containers/inputs/LetterReceivdSelect'
import CountrySelect from '../containers/inputs/CountrySelect'
import LoadingImage from '../containers/LoadingImage'
import ProgressButton from './buttons/ProgressButton'
import { DatePicker } from '@material-ui/pickers'
import WorkNotes from './WorkNotes'
import AuditTable from './AuditTable'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import * as verif from '../constants/incidentVerificationStatus'

const today = new Date();

const styles = {
    upldImage: {
      height: '100px',
      width: '100px',
      backgroundColor: 'lightblue',
      border: 'dashed',
      borderColor: 'darkblue'
    },
    dz: {
      height: '100px',
      width: '100px',
      borderColor: 'white',
      align: 'left',
      padding: 10
    },
    image: {
      padding: '0 4px'
    },
    imageContainer: {
        padding: '20px'
    },
    redBox: {
        border: 'solid',
        borderColor: 'red'
    }
  }
  const phoneRegex = /^\(?([0-9]{3})\)?[-.]?([0-9]{3})[-.]?([0-9)]{4})$/
  const handlePhoneInput = (value) => {
      if (value) {
        return (
            value.replace(phoneRegex, '($1) $2-$3')
        )
      }
  }

  function zipPlusFourCheck(name)
  {
    var regEx = /^[0-9-]+$/;
    if(name.match(regEx))
      { return true; }
    else      //alert("Please enter numbers or dash only");
      { return false; }
  } 

  function domesticUsPhoneCheck(name)
  {
    var regEx = /^[0-9-( )x]+$/;
    if(name.match(regEx))
      { return true; }
    else      //alert("Please enter numbers parens or dash only");
      { return false; }
  } 
          //1111111111 
        //0 1-(800)111-1111 x1234

  function lettersNumbersCheck(name) {
      var regEx = /^[0-9a-zA-Z]+$/;
      if(name.match(regEx)) { return true; }
      else                  { return false; }
    }  

    function certifiedTrackingNumberCheck(name) {
      var regEx = /^[0-9]+$/;
      if(name.match(regEx)) { return true; }
      else                  { return false; }
    }  

  class EditForm extends React.Component {

    downloadWordFile = async() =>  {
      // console.log('[components.EditForm.downloadWordFile] hmKey=',this.props.incident.hmKey)
      const data = await this.props.generateWord(this.props.incident.hmKey);
      // console.log('[components.EditForm.downloadWordFile] -- generateWord returned data=', data)
      const blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
      // console.log('[components.EditForm.downloadWordFile] -- blob=', blob)
      const link = document.createElement('a');
      // console.log('[components.EditForm.downloadWordFile] -- link=', link)
      link.href = window.URL.createObjectURL(blob);
      link.download = 'letter-to-dg-sender.docx';
      // console.log('[components.EditForm.downloadWordFile] -- calling.link.click()')
      link.click();
      // console.log('[components.EditForm.downloadWordFile] -- called link.click()')
    };

    componentDidMount = () => {
      
      this.props.onSnackbarClose()
      this.props.fetchLogs(this.props.incident.hmKey)
      this.props.deleteStagedImages()
      this.props.fetchImages(this.props.incident.hmKey)
      if (verif.SKIPPED_BY_USER === this.props.incident.barcodeCustomsVerificationStatus) {
        // console.log('initializing skip customs BC verification checkbox to on');
      this.props.incident.skipBarcodeCustomsVerification = 'on';
      }
      if (verif.SKIPPED_BY_USER === this.props.incident.barcodeCn35VerificationStatus) {
        // console.log('initializing skip Cn35 BC verification checkbox to on');
      this.props.incident.skipBarcodeCn35Verification = 'on';
      }
    }

    showHistory = () => {
      this.props.onSnackbarOpen()
    }

    delete = () => {
      confirmAlert({
        title: 'Confirm to Delete',
        message: 'Are you sure you want to delete?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.deleteIncident()
          },
          {
            label: 'No'
          }
        ]
      });
    }

    close = () => {
      confirmAlert({
        title: 'Confirm to Close',
        message: 'Are you sure you want to close the incident?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => this.props.closeIncident()
          },
          {
            label: 'No'
          }
        ]
      });
    }

    formatDate(date){
        return (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
    }                                                   

    validateCustomsBc = () => {
      if ('on' !== this.props.incident.skipBarcodeCustomsVerification) {
        // console.log('validateCustomsBc');
        var bcCustomsValue = this.props.incident.barcodeCustoms;
        var bcCustomsVerifNewValue = verif.PENDING;
        if ((undefined === bcCustomsValue) || (null===bcCustomsValue) || (''===bcCustomsValue) || (13!==bcCustomsValue.length) || (!lettersNumbersCheck(bcCustomsValue))) {
          bcCustomsVerifNewValue = verif.INVALID_PER_BIZ_RULES;
          // console.log('CustomsBc: invalid');
        }
        else {
          bcCustomsVerifNewValue = verif.VALID_PER_BIZ_RULES;
          // console.log('CustomsBc: valid');
        }
        if (verif.SKIPPED_BY_USER !== this.props.barcodeCustomsVerification) {
          this.props.handleFieldChange('barcodeCustomsVerificationStatus', bcCustomsVerifNewValue)
          // console.log('CustomsBc: set barcodeCustomsVerificationStatus=',bcCustomsVerifNewValue);
        }
        return bcCustomsVerifNewValue;
      }
      return verif.SKIPPED_BY_USER;
    }

    validateCn35Bc = () => {
      if ('on' !== this.props.incident.skipBarcodeCn35Verification) {
        // console.log('validateCustomsBc');        
        var bcCn35Value = this.props.incident.barcodeCn35;
        var bcCn35VerifNewValue = verif.PENDING;
        if ((undefined === bcCn35Value) || (null===bcCn35Value) || (''===bcCn35Value) || (29!==bcCn35Value.length) || (!lettersNumbersCheck(bcCn35Value))) {
          bcCn35VerifNewValue = verif.INVALID_PER_BIZ_RULES;
          // console.log('Cn35Bc: invalid');
        }
        else {
          bcCn35VerifNewValue = verif.VALID_PER_BIZ_RULES;
          // console.log('Cn35Bc: valid');        
        }
        if (verif.SKIPPED_BY_USER !== this.props.barcodeCn35Verification) {
          this.props.handleFieldChange('barcodeCn35VerificationStatus', bcCn35VerifNewValue)
          // console.log('Cn35Bc: set barcodeCn35VerificationStatus=',bcCn35VerifNewValue);         
        }
        return bcCn35VerifNewValue;
      }
      return verif.SKIPPED_BY_USER;
    }

    validate = () => {
        // console.log('[components/EditForm] -- validate')      
        var rcCustoms = this.validateCustomsBc();
        var rcCn35 = this.validateCn35Bc();
        let formIsValid = true
        if (
          this.props.incident.reportingContactName  === '' || this.props.incident.reportingContactName == null || 
          this.props.incident.reportingContactPhone  === '' || this.props.incident.reportingContactPhone == null || 
          this.props.incident.reportingContactEmail  === '' || this.props.incident.reportingContactEmail == null || 
          this.props.incident.reportingAirportHub  === '' || this.props.incident.reportingAirportHub == null || 
          this.props.incident.dateOfDiscovery  === '' || this.props.incident.dateOfDiscovery == null || 
          this.props.incident.originOfficeOfExchange  === '' || this.props.incident.originOfficeOfExchange == null || 
          this.props.incident.senderPhone  === '' || this.props.incident.senderPhone == null || 
          this.props.incident.senderName  === '' || this.props.incident.senderName == null || 
          this.props.incident.senderAddress  === '' || this.props.incident.senderAddress == null || 
          this.props.incident.senderState  === '' || this.props.incident.senderState == null ||
          this.props.incident.senderCity  === '' || this.props.incident.senderCity == null || 
          this.props.incident.senderZip  === '' || this.props.incident.senderZip == null || 
          this.props.incident.recipientPhone  === '' || this.props.incident.recipientPhone == null || 
          this.props.incident.recipientName  === '' || this.props.incident.recipientName == null || 
          this.props.incident.recipientAddress  === '' || this.props.incident.recipientAddress == null || 
          this.props.incident.recipientCity  === '' || this.props.incident.recipientCity == null || 
          this.props.incident.recipientCountry  === '' || this.props.incident.recipientCountry == null || 
          this.props.incident.recipientZip  === '' || this.props.incident.recipientZip == null || 
          this.props.incident.contentDescrnOnCustomsForm  === '' || this.props.incident.contentDescrnOnCustomsForm == null) 
        {
          formIsValid = false;
          // console.log('validate - form is invalid because something else')               
        } 
      // console.log('barcodeCustomsVerificationStatus',this.props.incident.barcodeCustomsVerificationStatus)
      if ( 0 >= this.props.incident.barcodeCustomsVerificationStatus || 0 >= rcCustoms ) {
        if (0 >= rcCustoms) {
          formIsValid = false;
          // console.log('validate - form is invalid because barcodeCustomsVerificationStatus=',this.props.incident.barcodeCustomsVerificationStatus )     
        }
        else {
          // console.log('validate - form would have been invalid because barcodeCustomsVerificationStatus was still=',this.props.incident.barcodeCustomsVerificationStatus ) 
          // console.log('validate - but we allow it since its being set to =',rcCustoms )     
        }  
      }
      if ( 0 >= this.props.incident.barcodeCn35VerificationStatus || 0 >= rcCn35 ) {
        if (0 >= rcCn35) {
          formIsValid = false; 
          // console.log('validate - form is invalid because barcodeCn35VerificationStatus=',this.props.incident.barcodeCn35VerificationStatus ) 
        }
        else {
          // console.log('validate - form would have been invalid because barcodeCn35VerificationStatus was still=',this.props.incident.barcodeCn35VerificationStatus ) 
          // console.log('validate - but we allow it since its being set to =',rcCn35 )     
        }              
      }              
      if (formIsValid) {
          // this.props.incident.status = Incidents.INPROCESS
          this.props.handleFieldChange('status',  Incidents.INPROCESS)
          this.props.onSubmit()
      }
      else {
          alert('To complete the form, more data is needed')
      }
    }

    render() { 
      // console.log('[components/EditForm] -- render') 
      const uploadIt = (acceptedFiles) =>{
          acceptedFiles.forEach((file) => {
                uploadImage(file, incident.hmKey)
          });   
        }
      const { incident, classes, selimages, page, uploadImage, onSnackbarClose, snackbarOpen, role } = this.props;

      return(
        <PageContainer>
            <Grid container direction='column' spacing={3}>
                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography gutterBottom variant='h5'>
                          {/* TODONE: Display Agency reporting incident, if reported by Agency */}
                            Dangerous Item -- 
                            {'AGENT'===incident.entityReportingIncident && incident.agencyReportingIncident}
                            { 'AGENT'!==incident.entityReportingIncident && incident.airlineReportingIncident }
                            -{ DateUtils.formatIdDate(incident.dateReported) }-{ incident.hmKey } -- Edit
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='CN-35 Barcode'
                            variant='outlined'
                            // id='barcodeCn35'
                            helperText='Required and must be 29 alphanumeric characters'
                            inputProps={{ minLength: 29, maxLength: 29 }}
                            error={ ( verif.SKIPPED_BY_USER !== incident.barcodeCn35VerificationStatus) &&(incident.barcodeCn35 == null || incident.barcodeCn35.length !== 29 || !lettersNumbersCheck(incident.barcodeCn35))}
                            value={ incident.barcodeCn35 || '' }
                            name='barcodeCn35'
                            onChange={ (event) => this.props.handleFieldChange('barcodeCn35', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography><label for='skipBarcodeCn35Verification' >
                            <input type='checkbox'
                              autoComplete='off'
                              fullWidth
                              label='Skip CN35 BC Verification'
                              variant='outlined'
                              name='skipBarcodeCn35Verification'
                              id='skipBarcodeCn35Verification'
                              checked={'on' === incident.skipBarcodeCn35Verification}
                              onChange={ () => {
                                if ('on' === incident.skipBarcodeCn35Verification) {
                                  this.props.handleFieldChange('skipBarcodeCn35Verification', 'off');
                                  this.props.handleFieldChange('barcodeCn35VerificationStatus', verif.PENDING);                            
                                }
                                else {
                                  this.props.handleFieldChange('skipBarcodeCn35Verification', 'on');
                                  this.props.handleFieldChange('barcodeCn35VerificationStatus', verif.SKIPPED_BY_USER);
                                }
                              }}
                            />
                            Skip CN35 BC Verification</label>
                        </Typography>                   
                    </Grid>
                    <TextField
                            type='hidden'
                            id='barcodeCn35VerificationStatus'
                            error={incident.barcodeCn35VerificationStatus == null || incident.barcodeCn35VerificationStatus.length > 4}
                            value={ incident.barcodeCn35VerificationStatus || '' }
                            readonly={true}
                        />
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customs Barcode'
                            variant='outlined'
                            helperText='Required and must be 13 alphanumeric characters'
                            inputProps={{ minLength: 13, maxLength: 13 }}
                            error={ ( verif.SKIPPED_BY_USER !== incident.barcodeCustomsVerificationStatus) &&(incident.barcodeCustoms == null || incident.barcodeCustoms.length !== 13 || !lettersNumbersCheck(incident.barcodeCustoms))}
                            value={ incident.barcodeCustoms || '' }
                            name='barcodeCustoms'
                            onChange={ (event) => this.props.handleFieldChange('barcodeCustoms', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography><label for='skipBarcodeCustomsVerification' >
                          <input type='checkbox'
                              autoComplete='off'
                              fullWidth
                              label='Skip Customs BC Verification'
                              variant='outlined'
                              name='skipBarcodeCustomsVerification'
                              id='skipBarcodeCustomsVerification'
                              checked={'on' === incident.skipBarcodeCustomsVerification}
                              onChange={ () => {
                                if ('on' === incident.skipBarcodeCustomsVerification) {
                                  this.props.handleFieldChange('skipBarcodeCustomsVerification', 'off');
                                  this.props.handleFieldChange('barcodeCustomsVerificationStatus', verif.PENDING);                            
                                  }
                                else {
                                  this.props.handleFieldChange('skipBarcodeCustomsVerification', 'on');
                                  this.props.handleFieldChange('barcodeCustomsVerificationStatus', verif.SKIPPED_BY_USER);
                                  }
                                }
                              }
                            />
                          Skip Customs BC Verification</label>
                      </Typography>
                    </Grid>
                    <TextField
                        type='hidden'
                        id='barcodeCustomsVerificationStatus'
                        error={incident.barcodeCustomsVerificationStatus == null || incident.barcodeCustomsVerificationStatus.length > 4}
                        value={ incident.barcodeCustomsVerificationStatus || '' }
                        readonly={true}
                    />
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Handling Unit Barcode'
                            id='barcodeHandlingUnit'
                            variant='outlined'
                            helperText='Must be 29 alphanumeric characters if filled'
                            error={(null !== incident.barcodeHandlingUnit) && (0 !== incident.barcodeHandlingUnit.length) && (incident.barcodeHandlingUnit.length !== 29 || !lettersNumbersCheck(incident.barcodeHandlingUnit))}
                            value={ incident.barcodeHandlingUnit || '' }
                            onChange={ (event) => this.props.handleFieldChange('barcodeHandlingUnit', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            helperText='no change allowed'
                            label='Reporting Airline'
                            readOnly
                            variant='outlined'

                            name='airlineReportingIncident'
                            error={null == incident.airlineReportingIncident || 0 === incident.airlineReportingIncident.length || '' === incident.airlineReportingIncident }
                            value={ incident.airlineReportingIncident || '' }
                        />
                    </Grid>

                    {/* <Grid item xs={12} md={3}> */}
                        <TextField
                            // fullWidth
                            type='hidden'
                            //label='Reporting Agency Abbr'
                            readOnly
                            // variant='outlined'
                            name='agencyReportingIncident'
                            value={ incident.agencyReportingIncident || '' }
                        /> 
                    {/* </Grid> */}

                    {/* <Grid item xs={12} md={4}> */}
                        <TextField
                          // fullWidth
                          type='hidden'
                          //label='Entity Type'
                          readOnly
                          // variant='outlined'
                          name='entityReportingIncident'
                          value={ incident.entityReportingIncident || '' }
                        />
                    {/* </Grid> */}
                    <Grid item xs={12} md={3}>
                        <DatePicker
                            id='todate'
                            helperText='no change allowed'
                            floatingLabelText="To"
                            container="inline" 
                            label='Date Reported'
                            format='MM/dd/yyyy'
                            variant='inline'
                            readOnly={true}
                            value={ incident.dateReported }
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Reporting Contact Name'
                            variant='outlined'
                            helperText='Required'
                            error={incident.reportingContactName == null}
                            value={ incident.reportingContactName || '' }
                            name='reportingContactName'
                            onChange={ (event) => this.props.handleFieldChange('reportingContactName', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            autoComplete='off'
                            fullWidth
                            label='Reporting Contact Phone'
                            variant='outlined'
                            helperText='Required'
                            error={incident.reportingContactPhone == null || incident.reportingContactPhone === ''}
                            value={handlePhoneInput(incident.reportingContactPhone)}
                            name='reportingContactPhone'
                            onChange={ (event) => this.props.handleFieldChange('reportingContactPhone', event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}> 
                        <TextField
                            fullWidth
                            label='Reporting Contact Email'
                            variant='outlined'
                            helperText='Required'
                            error={incident.reportingContactEmail == null}
                            value={ incident.reportingContactEmail || '' }
                            name='reportingContactEmail'
                            onChange={ (event) => this.props.handleFieldChange('reportingContactEmail', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <AirportDestinationSelect 
                            fullWidth
                            label='Reporting Airport Hub'
                            variant='outlined'
                            name='reportingAirportHub'
                            helperText='Required'
                            error={incident.reportingAirportHub == null}
                            value={ incident.reportingAirportHub || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('reportingAirportHub', event.target.value)}} 
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='todate'
                            floatingLabelText="To"
                            container="inline" 
                            label='Date of Discovery'
                            format='MM/dd/yyyy'
                            variant='inline'
                            defaultDate = { new Date() }
                            helperText='Required'
                            value={ incident.dateOfDiscovery }
                            onChange={ (event) => this.props.handleFieldChange('dateOfDiscovery', event)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <AirportOriginSelect 
                                fullWidth
                                label='USA Origin Airport (Office of Exchange/ISC)'
                                name='originOfficeOfExchange'
                                variant='outlined'
                                helperText='Required'
                                error={incident.originOfficeOfExchange == null}
                                value={ incident.originOfficeOfExchange || '' }
                                onChange =  {(event) => {this.props.handleFieldChange('originOfficeOfExchange', event.target.value)}} 
                            />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Airline Record/Incident Number'
                            variant='outlined'
                            value={ incident.airlineRecordNo || '' }
                            name='airlineRecordNo'
                            onChange={ (event) => this.props.handleFieldChange('airlineRecordNo', event.target.value)}
                            />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <InitialFindingSelect 
                            fullWidth
                            label='Initial Findings'
                            variant='outlined'
                            name='initialFinding'
                            helperText='Required'
                            error={incident.initialFinding == null}
                            value={ incident.initialFinding || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('initialFinding', event.target.value)}} 
                        />
                    </Grid>
                    {incident.initialFinding === 'DG' ?
                    <Grid item xs={12} md={3}>
                        <IcaoHazardClassSelect
                            fullWidth
                            label='Icao Hazard Class'
                            variant='outlined'
                            name='icaoHazardClass'
                            helperText='Required'
                            error={incident.icaoHazardClass == null}
                            value={ incident.icaoHazardClass || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('icaoHazardClass', event.target.value)}} 
                        />
                    </Grid>
                    :''}
                </Grid>

                {incident.initialFinding === 'DG' ?
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <IssueIdentifierSelect 
                        fullWidth
                        label='How Alarm Incident was Identified'
                        variant='outlined'
                        name='issueIdentificationMeans'
                        value={ incident.issueIdentificationMeans || '' }
                        onChange =  {(event) => {this.props.handleFieldChange('issueIdentificationMeans', event.target.value)}} 
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        { incident.icaoHazardClass !== 'Not Selected' && incident.icaoHazardClass !== null?
                        <DangerousItemSelect 
                            fullWidth
                            label='Dangerous Item Type'
                            variant='outlined'
                            name='dangerousItem'
                            helperText='Required'
                            error={incident.dangerousItem == null}
                            value={ incident.dangerousItem || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('dangerousItem', event.target.value)}} 
                            hazclass={incident.icaoHazardClass}
                        />
                        :''
                        }
                    </Grid>
                </Grid>
                :
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <IssueTypeSelect
                            fullWidth
                            label='Issue Type'
                            variant='outlined'
                            name='issueType'
                            helperText='Required'
                            error={incident.issueType == null}
                            value={ incident.issueType || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('issueType', event.target.value)}} 
                            />
                    </Grid>
                    { incident.issueType === 'RI'?
                    <Grid item xs={12} md={3}>
                        <RestrictedItemSelect
                            fullWidth
                            label='Restricted Item Type'
                            variant='outlined'
                            name='restrictedItem'
                            value={ incident.restrictedItem || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('restrictedItem', event.target.value)}} 
                        />
                    </Grid>
                    :
                    ''}
                </Grid>
                }

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label='Additional Comments'
                            value={ incident.commentsGeneral || '' }
                            name='commentsGeneral'
                            onChange={ (event) => this.props.handleFieldChange('commentsGeneral', event.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.container} variant='subtitle1'>Sender</Typography>
                    </Grid>
                </Grid>
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Company'
                            variant='outlined'
                            value={ incident.senderCompany || '' }
                            name='senderCompany'
                            onChange={ (event) => this.props.handleFieldChange('senderCompany', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Name'
                            helperText='Required'
                            error={incident.senderName == null}
                            value={ incident.senderName || '' }
                            variant='outlined'
                            name='senderName'
                            onChange={ (event) => this.props.handleFieldChange('senderName', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Address'
                            variant='outlined'
                            helperText='Required'
                            error={incident.senderAddress == null}
                            value={ incident.senderAddress || '' }
                            name='senderAddress'
                            onChange={ (event) => this.props.handleFieldChange('senderAddress', event.target.value)}
                        />          
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Address 2'
                            variant='outlined'
                            value={ incident.senderAddress2 || '' }
                            name='senderAddress2'
                            onChange={ (event) => this.props.handleFieldChange('senderAddress2', event.target.value)}
                        />         
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <UsStateSelect
                            fullWidth
                            label='State'
                            variant='outlined'
                            name='restrictedItem'
                            helperText='Required'
                            error={incident.senderState == null}
                            value={ incident.senderState || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('senderState', event.target.value)}}  
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='City'
                            variant='outlined'
                            helperText='Required'
                            error={incident.senderCity == null}
                            value={ incident.senderCity || '' }
                            name='senderCity'
                            onChange={ (event) => this.props.handleFieldChange('senderCity', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='ZIP'
                            variant='outlined'
                            helperText='Required'
                            // error={incident.senderZip == null}
                            error={
                              (
                                (null !== incident.senderZip) && (0 !== incident.senderZip.length) && 
                                ((incident.senderZip.length < 5) || (incident.senderZip.length > 10) || !zipPlusFourCheck(incident.senderZip))
                              )
                            }                            
                            value={ incident.senderZip || '' }
                            name='senderZip'
                            onChange={ (event) => this.props.handleFieldChange('senderZip', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            autoComplete='off'
                            fullWidth
                            label='Sender Phone'
                            variant='outlined'
                            helperText='Required'

                            error={
                              (
                                (null !== incident.senderPhone) && (0 !== incident.senderPhone.length) && 
                                ((incident.senderPhone.length < 10) || (incident.senderPhone.length > 25) || !domesticUsPhoneCheck(incident.senderPhone))
                              )
                            }                            


                            value={handlePhoneInput(incident.senderPhone)}
                            name='senderPhone'
                            onChange={ (event) => this.props.handleFieldChange('senderPhone', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' alignment='center' item spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.container} variant='subtitle1'>Recipient/Consignee</Typography>
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Company'
                            variant='outlined'
                            value={ incident.recipientCompany || '' }
                            name='recipientCompany'
                            onChange={ (event) => this.props.handleFieldChange('recipientCompany', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Name'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientName == null}
                            value={ incident.recipientName || '' }
                            name='recipientName'
                            onChange={ (event) => this.props.handleFieldChange('recipientName', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Address'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientAddress == null}
                            value={ incident.recipientAddress || '' }
                            name='recipientAddress'
                            onChange={ (event) => this.props.handleFieldChange('recipientAddress', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Address 2'
                            variant='outlined'
                            value={ incident.recipientAddress2 || '' }
                            name='recipientAddress2'
                            onChange={ (event) => this.props.handleFieldChange('recipientAddress2', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>                                           
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='City/Locale'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientCity == null}
                            value={ incident.recipientCity || '' }
                            name='recipientCity'
                            onChange={ (event) => this.props.handleFieldChange('recipientCity', event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Post Code'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientZip == null}
                            value={ incident.recipientZip || '' }
                            name='recipientZip'
                            onChange={ (event) => this.props.handleFieldChange('recipientZip', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <CountrySelect 
                            fullWidth
                            label='Country'
                            variant='outlined'
                            name='recipientCountry'
                            helperText='Required'
                            error={incident.recipientCountry == null}
                            value={ incident.recipientCountry || '' }
                            onChange =  {(event) => {this.props.handleFieldChange('recipientCountry', event.target.value)}} 
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            autoComplete='off'
                            fullWidth
                            label='Recipient Phone'
                            variant='outlined'
                            helperText='Required'
                            error={incident.recipientPhone == null || incident.recipientPhone === ''}
                            value={handlePhoneInput(incident.recipientPhone)}
                            name='recipientPhone'
                            onChange={ (event) => this.props.handleFieldChange('recipientPhone', event.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label='Content Description written from Customs Form'
                            helperText='Required'
                            error={incident.contentDescrnOnCustomsForm == null}
                            value={ incident.contentDescrnOnCustomsForm || '' }
                            name='contentDescrnOnCustomsForm'
                            onChange={ (event) => this.props.handleFieldChange('contentDescrnOnCustomsForm', event.target.value)}
                        />               
                    </Grid>
                </Grid>



                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                  <Grid item xs={12}>
                     <WorkNotes hmKey={incident.hmKey} />
                   </Grid>
                </Grid>

                <Grid container direction='row' className={classes.imageContainer} justify='flex-start' alignment='center' item spacing={2}>
                    {selimages.map ?
                      selimages.map((image, index)=>(
                    <LoadingImage image={image.image} />
                      ))
                    :
                    ''       
                    }
                    {role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT ? 
                    <Dropzone  accept='.jpg, .png' onDrop={acceptedFiles => uploadIt(acceptedFiles)}>
                        {({getRootProps, getInputProps}) => (
                        <section>
                          <div className={classes.dz} {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className={classes.upldImage}>Drag 'n' drop, or click to select files</div>
                          </div>
                        </section>
                        )}
                    </Dropzone>
                    :
                    ''}
                </Grid>


                { ((role === Roles.DANGEROUS_GOODS_ADMIN ) && (incident.status === Incidents.INPROCESS)) ?
                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={2}>
                      <ProgressButton
                          color='primary'
                          variant='contained'
                          onClick={()=>{
                            this.downloadWordFile()
                          }}
                      >
                        Generate Customer Letter
                      </ProgressButton>
                    </Grid>

                </Grid>
                :
                ''}  

                { ((role === Roles.DANGEROUS_GOODS_ADMIN ) && (incident.status === Incidents.INPROCESS || incident.status === Incidents.CLOSED) ) ?
                <Grid container direction='column' justify='flex-start' alignment='center' item spacing={2}> 
                                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>

                        {/* <TextField

                            fullWidth
                            name='customerLetterMailedStatus'
                            readOnly
                            label='Customer Letter Mailed Status'
                            // helperText='Required'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterMailedStatus || '-1' }
                            onChange={ (event) => this.props.handleFieldChange('customerLetterMailedStatus', event.target.value)}
 */}
                        <LetterMailedSelect
                            fullWidth
                            label='Customer Letter Mailed Status'
                            variant='outlined'
                            name='customerLetterMailedStatus'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterMailedStatus || '-1' }
                            onChange =  {(event) => {this.props.handleFieldChange('customerLetterMailedStatus', event.target.value)}}  
                        />                        


                    </Grid>
                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterMailedDate'
                            container="inline" 
                            label='Letter Mailed Date'
                            formatDate={this.formatDate}
                            defaultDate = { new Date() }
                            // helperText='Required'
                            value={ incident.customerLetterMailedDate }
                            onChange={ (event) => this.props.handleFieldChange('customerLetterMailedDate', event)}
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Filename'
                            inputProps={{ maxLength: 40 }}
                            // helperText='Required'
                            // error={incident.customerLetterFilename == null}
                            value={ incident.customerLetterFilename || '' }
                            name='customerLetterFilename'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterFilename', event.target.value)}
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter File Location'
                            // helperText='Required'
                            // error={incident.customerLetterFileLocation == null}
                            value={ incident.customerLetterFileLocation || '' }
                            name='customerLetterFileLocation'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterFileLocation', event.target.value)}
                        />               
                    </Grid>   
                    </Grid>                    
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Certified Mail Tracking No.'

                            helperText='20 digit number, no spaces'

                            error={((null !== incident.customerLetterCrfTrackNo) && (0 !== incident.customerLetterCrfTrackNo.length) && ((incident.customerLetterCrfTrackNo.length < 20) || (incident.customerLetterCrfTrackNo.length > 20) || !certifiedTrackingNumberCheck(incident.customerLetterCrfTrackNo)))}                            
                            value={ incident.customerLetterCrfTrackNo || '' }
                            name='customerLetterCrfTrackNo'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterCrfTrackNo', event.target.value)}
                        />               
                    </Grid>                                                                                                 
                    <br></br>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>

                        {/* <TextField

                            fullWidth
                            label='Customer Letter Response Status'
                            helperText='Required'
                            // error={incident.customerLetterResponseStatus == null}
                            value={ incident.customerLetterResponseStatus || '-1' }
                            name='customerLetterResponseStatus'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseStatus', event.target.value)}

                        />    */}



                      <LetterReceivdSelect
                            fullWidth
                            label='Customer Letter Received Status'
                            variant='outlined'
                            name='customerLetterResponseStatus'
                            // error={incident.customerLetterMailedStatus == null}
                            value={ incident.customerLetterResponseStatus || '-1' }
                            onChange =  {(event) => {this.props.handleFieldChange('customerLetterResponseStatus', event.target.value)}}  
                        />             
                    </Grid>




                    <Grid item xs={12} md={3}>
                        <DatePicker 
                            id='customerLetterResponseDate'
                            container="inline" 
                            label='Letter Received Date'
                            formatDate={this.formatDate}
                            // defaultDate = { new Date() }
                            // helperText='Required'
                            value={ incident.customerLetterResponseDate }
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseDate', event)}
                        />
                    </Grid>
                    </Grid>
                    <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}> 
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response Filename'
                            // helperText='Required'
                            // error={incident.customerLetterResponseFilename == null}
                            value={ incident.customerLetterResponseFilename || '' }
                            name='customerLetterResponseFilename'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseFilename', event.target.value)}
                        />               
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TextField
                            fullWidth
                            label='Customer Letter Response File Location'
                            // helperText='Required'
                            // error={incident.customerLetterResponseFileLoc == null}
                            value={ incident.customerLetterResponseFileLoc || '' }
                            name='customerLetterResponseFileLoc'
                            onChange={ (event) => this.props.handleFieldChange('customerLetterResponseFileLoc', event.target.value)}
                        />               
                    </Grid>  
                    </Grid>                                                                              
                </Grid>
                    :
                    ''}

                <Grid container direction='row' justify='flex-start' alignment='center' item spacing={2}>
                    {(role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT) ?
                    <Grid item xs={12} md={2}>
                        <ProgressButton
                            color='primary'
                            variant='contained'
                            onClick={()=>{
                              this.validate()
                            }}
                        >
                            {incident.status === Incidents.DRAFT ?
                            <Typography>SUBMIT COMPLETE</Typography> 
                            :
                            <Typography>SUBMIT (complete)</Typography> 
                            }
                        </ProgressButton>
                    </Grid>
                    :
                    ''}

                    <Grid item xs={12} md={2}>
                        {(role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT) && incident.status === Incidents.DRAFT ?
                        <ProgressButton
                            color='primary'
                            variant='contained'
                            onClick={()=>{
                              this.validateCustomsBc();
                              this.validateCn35Bc();
                              this.props.saveDraft()
                            }}
                        >
                            <Typography>SAVE AS DRAFT</Typography>            
                        </ProgressButton>
                        :''
                        }             
                    </Grid>

                    { (role === Roles.DANGEROUS_GOODS_ADMIN || role === Roles.CARRIER || role === Roles.AGENT) && incident.status === Incidents.DRAFT ?
                    <Grid item xs={12} md={2}>
                        <ProgressButton
                            color='primary'
                            variant='contained'
                            onClick={()=>{
                              this.delete()
                            }}
                        >
                            Delete Incident (draft)
                        </ProgressButton>
                    </Grid>
                    :
                    ''}

                    { ((role === Roles.DANGEROUS_GOODS_ADMIN ) && (incident.status === Incidents.INPROCESS)) ?
                    <Grid item xs={12} md={2}>
                        <ProgressButton
                            color='primary'
                            variant='contained'
                            onClick={()=>{
                              this.close()
                            }}
                        >
                          CLose Incident (in-process)
                        </ProgressButton>
                    </Grid>
                    :
                    ''}

                    <Grid item xs={12} md={2}>
                      <ProgressButton
                          color='primary'
                          variant='contained'
                          onClick={()=>{
                            this.showHistory()
                          }}
                      >
                        View History
                      </ProgressButton>
                    </Grid>
                  
                </Grid>

            </Grid>
          
            <AuditTable logs={this.props.hm_logs} snackbarClose={onSnackbarClose} snackbarOpen={snackbarOpen}/>
        </PageContainer>
      )
    }
  }

export default withStyles(styles)(EditForm)